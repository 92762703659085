import React, { useEffect, useState } from "react";
// import { RETURNS_UI_URLS } from "../../../../constants";
// import active from "./../../../../assets/images/wearhumans/Active.svg";
import "./return-author.scss";
import dateFormat from 'dateformat';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import { RETURNS_UI_URLS } from "../../../../constants";
import { Alert, Snackbar } from "@mui/material";
import EmailDialog from "../../email-dialog/email-select-dialog";
import { RETURNS_UI_URLS } from "../../../../constants";

function ReturnAuthor(data) {
    const [filterData, setFilter] = useState({});
    const [showtooltip, setShowTooltip] = useState(false);
    // const [accBg4, setAccBg4] = useState(false);
    const [accShow4, setAccShow4] = useState(true);
    const [data1, setRetailerData] = React.useState({});
    const [collapseFour, setCollapseFour] = useState(true);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackbarProp, setSnackbarProp] = useState({});
    const[open,setOpen]= React.useState(false);
    const [emailData, setEmailData] = useState('');

    // const [confirmLoaderEnable, setConfirmLoaderEnable] = React.useState(false);


    // let data1 = (JSON.parse(sessionStorage.getItem('retailerData')));
    let res = data.data;

    useEffect(() => {
        setFilter(res);

        // const returnSearch = async () => {     

        setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
        // try {
        //     setConfirmLoaderEnable(true);
        //     const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'returns/' + res?.return_id, {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
        //         }
        //     });
        //     if (!response) {
        //         setConfirmLoaderEnable(false);
        //         setFilter(null);
        //     }
        //     if (response?.status === 200) {
        //          const result = await response.json();
        //          if(result){
        //              setFilter(result);
        //              setConfirmLoaderEnable(false);
        //             //  childDataLoader(result);
        //          }else{
        //             setFilter(null);
        //             setConfirmLoaderEnable(false);    
        //          }
        //     }
        //     else {
        //         setFilter(null);
        //         setConfirmLoaderEnable(false);
        //     }
        // }
        // catch (error) {
        //     setFilter(null);
        //     setConfirmLoaderEnable(false);

        // }
        // }
        //returnSearch();
    }, [res]);



    const HtmlTooltipForAddress = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));
    const HtmlTooltipForDetails = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            // width: '400px',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));

    const handleTouchStart = () => {
        setShowTooltip(true);
    }
    const handleTouchEnd = () => {
        setShowTooltip(false)
    }

    const formatingDateForDisplay = (date) => {
        return dateFormat(date, "mmmm d, yyyy");
    }

    const removeunderscore = (returnstatus) => {
        let data = returnstatus?.replaceAll('_', ' ');
        return data?.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
            return match?.toUpperCase();
        });;
    }

    const addDefaultImg = ev => {
        ev.target.src = "/no-image.svg";
        ev.target.style = 'width:45%';
    }
    const displaySizeandColor = (size, color) => {
        return size && color ? (<span>Size: {size} &nbsp;&nbsp;|&nbsp;&nbsp; Color: {color}</span>) : (size && !color ? ('Size: ' + size) : (!size && color ? ('Color: ' + color) : ''))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const clikedAcc4 = () => {
        setCollapseFour(!collapseFour);
        // setCollapseThree(true); 
        // setCollapseTwo(true); 
        // setCollapseOne(true); 
        // setAccBg4(true);
        // setAccShow1(false);
        // setAccShow2(false);
        // setAccShow3(false);
        setAccShow4(true);
    }
    const openEmailDialog = (returnId, shipId, emailId) => {        
        let d = {
            shipId:shipId,
            returnId:returnId,
            emailId:emailId
        }
        setEmailData(d)
        setOpen(true);
    };

    const dialogClose = (value) => {
        if(value){
          setSnackOpen(true);
          setSnackbarProp({ style: 'success', msg: 'Notification sent to ' + value.recipients?.to[0]});
        }
        setOpen(false);
    };

    const genarateLabelURL = async (return_id, shipment_id, k) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'returns/' + return_id + '/shipment/' + shipment_id + '/generate-label', {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            });
            if (!response) {
            }
            if (response?.status === 200) {
                const result = await response.json();
                if (result) {
                    if(filterData.return_shipment_summaries[k].label_summary){
                        filterData.return_shipment_summaries[k].label_summary.url = result?.url;
                    }
                    else{
                      let data = {
                        url:result?.url
                      }
                      filterData.return_shipment_summaries[k].label_summary = data;
                    }
                    setFilter(filterData);
                    setSnackOpen(true);
                    setSnackbarProp({ style: 'success', msg: 'Generate Label Successfully.' })
                }
            }
            else{
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Failed To Generate Label.' })
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Failed To Generate Label.' })
        }
    }
    const genaratePackageSlipURL = async (shipment_id, k) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.ADMIN_BASE_URL + 'return-shipment/' + shipment_id + '/regenerate-packing-slip', {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            });
            if (!response) {
            }
            if (response?.status === 200) {
                const result = await response.json();
                if (result) {
                    filterData.return_shipment_summaries[k].packing_slip_url = result?.url;
                    setFilter(filterData);
                    setSnackbarProp({ style: 'success', msg: 'Package Slip Generated Successfully.' })
                }
            }
            else{
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Failed To Generate Package Slip.' })
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Failed To Generate Package Slip.' })
        }
    }

    return (
        <div >
            {/* <div className="align-center loader">Getting label info...</div>: */}
            <div className="row mt-16">
                <div className="col-12 card-wrap"><div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item mb-20">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className={`full-width text-left p-10 f-16 fw-500 acc-btn show ${data1.type}`} type="button" onClick={clikedAcc4}
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                <div className="row ml-0">
                                    <span className="col-10 col-md-11 white-color item-center">Download Label(s)</span>
                                    {/* <span className="fw-500 col-4 white-color" >
                                            {selectedReason}
                                            </span> */}
                                    <span className="text-right icon-size col-1" >
                                        {collapseFour && <ExpandMoreIcon className={`f-30 white-color`} />}
                                        {!collapseFour && <ExpandLessIcon className={`f-30 white-color`} />}
                                    </span>
                                </div>
                            </button>
                        </h2>
                        <div id="flush-collapseFour" className={`accordion-collapse collapse ${accShow4 ? 'show' : ''}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                {filterData ? <div>
                                    <div className="container underline" id="section_1">
                                        <div className="row py-10 f-14 hide-m">
                                            <div className="col-md col-sm col-12 mdisplay-flex">
                                                <div className='color-text fw-500'>Order Placed</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16 text-trans`}>{formatingDateForDisplay(filterData?.order_info?.placed_dttm?.actual_dttm)}</div>
                                            </div>
                                            {data1?.type !== 'janieandjack' ? <div className="col-md col-sm col-12 mdisplay-flex">
                                                <div className='color-text fw-500'>Total Refund</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>$ {filterData?.refund_amount?.amount ? filterData?.refund_amount?.amount.toFixed(2) : '0'}</div>
                                            </div> : ''}
                                            <div className="col-lg col-md col-sm col-12 mdisplay-flex">
                                                <div className='width-fit-content'>
                                                    <HtmlTooltipForAddress enterTouchDelay={0}
                                                        title={
                                                            <React.Fragment>
                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} >
                                                                    <div className="fw-600">{filterData?.order_info?.buyer_address?.name}</div>
                                                                    <div className="fw-400 f-12">{filterData?.order_info?.buyer_address?.address1}</div>
                                                                    <div className="fw-400 f-12">{filterData?.order_info?.buyer_address?.city},&nbsp;{filterData?.order_info?.buyer_address?.province}</div>
                                                                    <div className="fw-400 f-12">{filterData?.order_info?.buyer_address?.country}, &nbsp;{filterData?.order_info?.buyer_address?.zip_code}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        }>
                                                        <span className='mdisplay-flex'>
                                                            <div className='color-text fw-500'>Ship to</div>
                                                            <div className={`fw-600 ${data1?.type} cursor-pointer mm-l16`}>{filterData?.order_info?.buyer_address?.name} <span className="text-color">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                                </svg></span>
                                                            </div></span>
                                                    </HtmlTooltipForAddress>
                                                </div>
                                            </div>
                                            <div className="col-lg col-md col-sm col-12 mdisplay-flex">
                                                <div className='color-text fw-500'>Order Number</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{filterData?.order_info?.order_number}</div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-2 col-12 mdisplay-flex">
                                                <div className='color-text fw-500'>RMA#</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{filterData?.rma_number}</div>
                                            </div>
                                        </div>
                                        <div className='d-md-none py-10 f-14'>
                                            <div className='color-text fw-500'>RMA#:&nbsp;
                                                <HtmlTooltipForAddress enterTouchDelay={0}
                                                    title={
                                                        <React.Fragment>
                                                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                {/* <div className="row m-0 p-10 f-14 bg-color br-lf-rt-5" >
                                            <div className="col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                <div className='color-text fw-500'>Order Placed: &nbsp;</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16 text-trans`}>{formatingDateForDisplay(filterData?.order_info?.placed_dttm?.actual_dttm)}</div>
                                            </div>
                                            {data1?.type !== 'janieandjack' ?<div className="col-md-2 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                <div className='color-text fw-500'>Total Refund: &nbsp;&nbsp;</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>$ {(filterData?.refund_amount?.amount) ? (filterData?.refund_amount?.amount) : '0'}</div>
                                            </div>:''}
                                            <div className="col-md-4 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                <div className='color-text fw-500'>Ship to: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                <div className={`fw-600 ${data1?.type} cursor-pointer mm-l16`}>{filterData?.order_info?.buyer_address.name} </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                <div className='color-text fw-500'>Order Number: </div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{filterData?.order_info?.order_number}</div>
                                            </div>
                                        </div> */}
                                                                <div>Order Placed : &nbsp;{formatingDateForDisplay(filterData?.order_info?.placed_dttm?.actual_dttm)}</div>
                                                                <div>Order Number : &nbsp;{filterData?.order_info?.order_number}</div>
                                                                {data1?.type !== 'janieandjack' ? <div>Total Refund : &nbsp;$ {(filterData?.refund_amount?.amount) ? (filterData?.refund_amount?.amount.toFixed(2)) : '0'}</div> : ''}
                                                                <div>Ship to : &nbsp;{filterData?.order_info?.buyer_address?.name}</div>
                                                            </div>
                                                        </React.Fragment>}>
                                                    <span
                                                        className={`fw-600 ${data1?.type} text-underline`}>{filterData?.rma_number}</span>
                                                </HtmlTooltipForAddress>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row m-0">
                                            <div className="col-md-12 px-0 mt-10">
                                                <div className="f-14 fw-700 dark-grey-color">
                                                    {(removeunderscore(filterData?.status))?.charAt(0).toUpperCase() + (removeunderscore(filterData?.status))?.slice(1).toLowerCase() + ' on '}{formatingDateForDisplay(filterData?.requested_dttm?.formatted_dttm)}
                                                </div>
                                            </div>
                                            {(filterData?.return_shipment_summaries && filterData?.return_shipment_summaries.length > 0) ? filterData?.return_shipment_summaries.map((lData, k) => {
                                                return (
                                                    <div className="p-0" key = {k}>
                                                        {lData?.vendor ? <div className="f-14 fw-400 mt-5px">
                                                            Product(s) Sold and Shipped by: <span className={`fw-600 ${data1.type}`}>{lData?.vendor}</span>
                                                        </div> : ''}
                                                        <div key={k} className={`row ml-0 mt-10 mb-10 px-0 ${filterData?.return_shipment_summaries?.length > k + 1 ? 'bottom-dashed-line' : ''}`}>
                                                            <div className={`col-xs-9 col-sm-9 col-md-9`}>

                                                                {lData?.line_items?.map((linedata, j) => {
                                                                    return (
                                                                        <div className="row" key={j}>
                                                                            <div className="col-lg-3 col-md-3 col-sm-3 col-2 text-center px-0">
                                                                                {data1.type !== 'fluentcommerce' ?
                                                                                    <span>
                                                                                        <img className={`img-block mb-10 cursor-pointer ${!linedata.line_item?.image_url ? "width-45per" : "border-image"}`} alt='linedata.line_item?' onClick={() => window.open(linedata.line_item?.routing_url ? linedata.line_item?.routing_url : data1.store_url)}
                                                                                            src={linedata.line_item?.image_url ? `${linedata.line_item?.image_url}` : '/no-image.svg'}
                                                                                            onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                                    </span> :
                                                                                    <span>                 
                                                                                     <img className={`img-block mb-10 cursor-pointer ${!linedata.line_item?.image_url ? "width-45per" : "border-image"}`} alt='linedata.line_item?' onClick={() => window.open(data1.store_url)}
                                                                                        src={linedata.line_item?.image_url ? `${linedata.line_item?.image_url}` : '/no-image.svg'}
                                                                                        onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                                    </span>}
                                                                            </div>
                                                                            <div className="col-lg-9 col-md-9 col-9 col-sm-9 mm-l16">
                                                                                {linedata?.line_item?.product_name ? <div className={`fw-500 f-14 ${data1?.type} cursor-pointer mb-5px ${linedata?.line_item?.product_name?.length > 60 ? '' : 'width-fit-content'}`} >
                                                                                    <HtmlTooltipForDetails enterTouchDelay={0}
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                                    {/* {linedata?.line_item?.product_description !== null && linedata?.line_item?.product_description ?
                                                                                <div>Description : &nbsp;{linedata?.line_item?.product_description}</div> : ""} */}
                                                                                                    {linedata?.line_item?.product_name !== null && linedata?.line_item?.product_name ?
                                                                                                        <div>Product Name: &nbsp;{linedata?.line_item?.product_name}</div> : ''}
                                                                                                    {linedata?.line_item?.quantity !== null && linedata?.line_item?.quantity >= 0 ?
                                                                                                        <div>Ordered Qty : &nbsp;{linedata?.line_item?.quantity}</div> : ''}
                                                                                                    {linedata?.line_item?.returnable_quantity !== null && linedata?.line_item?.returnable_quantity >= 0 ?
                                                                                                        <div>Returnable Qty : &nbsp;{linedata?.line_item?.returnable_quantity}</div> : ''}
                                                                                                </div>
                                                                                            </React.Fragment>}>
                                                                                        {showtooltip || (
                                                                                            <span className='mdisplay-flex'>
                                                                                                <div className='display-ellipsis'>{linedata?.line_item?.product_name}</div>
                                                                                            </span>
                                                                                        )}
                                                                                    </HtmlTooltipForDetails>
                                                                                </div>:
                                                                               <div className={`fw-500 f-14 ${data1?.type}`}>{linedata?.line_item?.sku}</div>}
                                                                                {(linedata?.line_item?.color || linedata?.line_item?.size) ? <div className='row mb-5px f-12 '>
                                                                                    <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(linedata?.line_item?.size, linedata?.line_item?.color)}</div>
                                                                                </div> : ''}
                                                                                {/* <div className="fw-500 f-s14 grey-color">{filterData?.line_item.product_description}</div> */}
                                                                                {/* {linedata?.line_item??.routing_url ?  */}
                                                                                <div className='row'>
                                                                                    <label className="mb-16 f-12 display-flex align-items-center qty-wrap custom-select">Qty:&nbsp;&nbsp;{linedata?.line_item?.quantity}</label>
                                                                                    {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(filterData?.line_item.routing_url)}>Buy it again </button>
                                                  <button className=" secondary-btn w-125 ml-10" onClick={() => window.open(filterData?.line_item.routing_url)}>View your item </button> */}
                                                                                </div>
                                                                                {/* : ''} */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 px-0">
                                                                <div className='row text-right mr-10 mm-x0'>
                                                            {lData?.label_summary?.url ? <button className={`${data1?.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => window.open(lData?.label_summary?.url)}>Download Label</button> :
                                                                <button className={`${data1?.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => genarateLabelURL(filterData.return_id, lData.id, k)}>Generate Label</button>}
                                                           {lData?.label_summary?.url  ? (lData?.packing_slip_url ? <button className={`${data1.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => window.open(lData?.packing_slip_url, "download")}>Package Slip </button> :
                                                                <button className={`${data1.type ? data1.type + '-btn' : ''} mb-10 float-right`} onClick={() => genaratePackageSlipURL(lData.id, k)}>Generate Package Slip </button>) : ''}
                                                            {(lData?.label_summary?.url && lData?.packing_slip_url) ? <button className={` ${data1.type} tertiary-btn mb-16`} onClick={e => openEmailDialog(filterData.return_id, lData.id, filterData?.order_info?.email_id)}>Send Email</button> : ''}

                                                        </div>
                                                            </div>
                                                        </div></div>)
                                            }
                                            ) : ''}
                                        </div>
                                    </div> </div> : <div className='f-14 fw-500 text-center mt-16 mb-20'>No Data Found</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                    {/* <div className="col-12 col-sm-12 col-md-12 text-center">
                    <img src={active} rel="" alt="something" />
                </div>
                <div className="fw-600 sucess-return text-center p-10"> Return Authorization Successful</div> */}
                    {/* <p className="fw-500 p-10 f-16 lh-30 text-center">You will receive an e-mail includes instructions and return label. Please print<br /> and paste the return label on the original package. Once we receive the<br />                  returned item, we will issue the refund.</p>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                        <button className="primary-btn fw-900 shop-btn f-16 ml-24" onClick={() => window.open(data.store_url, '_blank')}>Continue Shopping</button>
                    </div>
                </div> */}
                    {/* label info */}
                    {/* {filterData ? 
                
                } */}

                </div>
            </div>
            <EmailDialog  open={open} emailData={emailData}
        onClose={dialogClose}/>
            <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarProp.style}
                    variant="filled"
                    sx={{ width: '100%' }}
                >{snackbarProp.msg}
                </Alert></Snackbar>
        </div>
    )
}

export default ReturnAuthor;
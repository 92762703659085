// import upload from "./../../../assets/images/wearhumans/Upload icon.svg";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem, Snackbar, Alert } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import React, { useEffect, useState } from "react";
import dateFormat from 'dateformat';
import './return-item.scss';
import ReturnAuthorization from './../retrun item/return-authorization/return-author';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SimpleDialog from '../controlled-popup/popup';
import HomeIcon from '@mui/icons-material/Home';
import { RETURNS_UI_URLS } from "../../../constants.js";


function ReturnItem({ data, childData, reasonData }) {
    let orderData = data;
    const [collapseOne, setCollapseOne] = useState(true);
    const [collapseTwo, setCollapseTwo] = useState(true);
    const [collapseThree, setCollapseThree] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedReasonData, setSelectedReasonData] = useState([]);
    const [selectedDummyReasonData, setSelectedDummyReasonData] = useState([]);
    const [selectedSavedReasonData, setSelectedSavedReasonData] = useState([]);
    const [reasonsResult, setReasonResult] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [shipmentData, setShipmentData] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [getSubReasonData, setGetSubReasonData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [showtooltip, setShowTooltip] = useState(false);
    const [accShow1, setAccShow1] = useState(true);
    const [accShow2, setAccShow2] = useState(false);
    const [accShow3, setAccShow3] = useState(false);
    const [accBg1, setAccBg1] = useState(true);
    const [accBg2, setAccBg2] = useState(false);
    const [accBg3, setAccBg3] = useState(false);
    const [data1, setRetailerData] = React.useState({});
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [isLoaderEnable, setIsLoaderEnable] = useState(false);
    const [requestedResponse, setRequestedResponse] = useState({});
    const [snackbarProp, setSnackbarProp] = useState({});
    const [disableReasonBtn, setDisableReasonBtn] = React.useState(false);
    const [disablePaymentBtn, setDisablePaymentBtn] = React.useState(false);
    const [disableReturnBtn, setDisableReturnBtn] = React.useState(false);
    const [confirmLoaderEnable, setConfirmLoaderEnable] = React.useState(false);
    const [loaderMessage, setLoaderMessage] = React.useState('Confirnimg return request...');


    let retailerInfo = JSON.parse(sessionStorage.getItem('retailerData'));


    const navigateToMyOrders = (event) => {
        if (JSON.stringify(selectedDummyReasonData) === JSON.stringify(selectedReasonData)) {
            childData(isSubmitted);
            event.preventDefault();
        } else {
            openDialog();
        }
    }
    const openDialog = () => {
        setOpen(true);
    };

    const dialogClose = (value) => {
        setOpen(false);
        if (value) {
            childData(isSubmitted);
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('selectedReasonData');
        }
    };
    // const navigateToReturned = (value) => {
    //     childData(value);
    // }
    // const getChildData = (value)=>{        
    //         setConfirmLoaderEnable(value);
    //         setLoaderMessage('Getting label info...')
    // }


    useEffect(() => {
        setRetailerData(JSON.parse(sessionStorage.getItem('retailerData')));
        window.scrollTo(0, 0)
        setReasonResult([]);
        setSelectedReasonData([]);
        const getReasons = async () => {
            setIsLoaderEnable(true);
            try {
                const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + (JSON.parse(sessionStorage.getItem('RPI'))?.rpi_number) + '/return-attributes', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId)
                    }
                })
                if (!response) {
                    setSnackOpen(true);
                    setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                }
                if (response?.status === 200) {
                    let result = await response.json();
                    if (result) {
                        setReasonResult(result);
                        let array = [];
                        for (let i = 0; i < result.length; i++) {
                            let t = {
                                "sku": result[i]?.sku,
                                "shipment_id": result[i]?.shipment_id,
                                "return_attribute": {
                                    "return_reason": {
                                        "code": "",
                                        "title": ""
                                    },
                                    "return_sub_reason": {
                                        "code": "",
                                        "title": ""
                                    },
                                    "item_condition": {
                                        "code": "",
                                        "title": ""
                                    },
                                    "customer_notes": "",
                                    "uploaded_images": [],
                                },
                            }
                            array.push(t)
                        }
                        setSelectedDummyReasonData(JSON.parse(JSON.stringify(array)));
                        sessionStorage.setItem('selectedDummyReasonData', JSON.stringify(array));
                        setSelectedReasonData(array);
                    }
                }
                else {
                    setSnackOpen(true);
                    setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });

                }
                setIsLoaderEnable(false);
            }
            catch (error) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                setIsLoaderEnable(false);
            }
        }
        if (sessionStorage.getItem('RPI') && !(reasonData && reasonData.length > 0)) {
            getReasons();
        }
        else {
            setReasonResult(reasonData);
            let array = [];
            for (let i = 0; i < reasonData.length; i++) {
                let t = {
                    "sku": reasonData[i]?.sku,
                    "shipment_id": reasonData[i]?.shipment_id,
                    "return_attribute": {
                        "return_reason": {
                            "code": "",
                            "title": ""
                        },
                        "return_sub_reason": {
                            "code": "",
                            "title": ""
                        },
                        "item_condition": {
                            "code": "",
                            "title": ""
                        },
                        "customer_notes": "",
                        "uploaded_images": [],
                    },
                }
                array.push(t)
            }
            setSelectedDummyReasonData(JSON.parse(JSON.stringify(array)));
            sessionStorage.setItem('selectedDummyReasonData', JSON.stringify(array));
            setSelectedReasonData(array);
        }
    }, [reasonData]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const openAcc1 = (event) => {
        setDisableReasonBtn(true);
        updateReturnReason();
    }

    const openAcc2 = (event) => {
        setDisablePaymentBtn(true);
        updateRefundMethod();
    }

    const scrollToParticularSection = (id) => {
        const violation = document.getElementById(id);
        violation?.scrollIntoView({
            block: 'start',
            // behavior: 'smooth',
        });
    }

    const updateReturnReason = async (event) => {
        let dd = JSON.parse(JSON.stringify(selectedReasonData))
        for (let i = 0; i < dd.length; i++) {
            delete dd[i].return_attribute.item_condition.description;
            delete dd[i].return_attribute.return_reason.preferences;
            delete dd[i].return_attribute.return_reason.sub_reasons;
        }
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + (JSON.parse(sessionStorage.getItem('RPI'))?.rpi_number) + '/selected-return-attributes', {
                method: 'POST',
                body: JSON.stringify(dd),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            })
            if (!response) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
            if (response?.status === 200) {
                let result = await response.json();
                setSelectedSavedReasonData(JSON.parse(JSON.stringify(selectedReasonData)));
                setAccBg1(true);
                setAccShow1(false);
                setDisablePaymentBtn(false);
                if (!(result?.disable_refund_methods_selection)) {
                    setAccBg2(true);
                    setAccShow2(true);
                    setAccBg3(false);
                    setAccShow3(false);
                    setSelectedPayment([]);
                    paymentMethod(result?.consolidated_refund_methods);
                    setTimeout(() => {
                        scrollToParticularSection('section_2');
                    }, 1000)
                }
                else {
                    setAccBg3(true);
                    setAccShow3(true);
                    setSelectedShipment([]);
                    shippingMethod(result?.consolidated_return_methods);
                    setTimeout(() => {
                        scrollToParticularSection('section_3');
                    }, 1000)
                }
            }
            else {
                setDisableReasonBtn(false);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
        }
    }
    const updateRefundMethod = async (event) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + (JSON.parse(sessionStorage.getItem('RPI'))?.rpi_number) + '/selected-refund-methods', {
                method: 'POST',
                body: JSON.stringify(selectedPayment),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            })
            if (!response) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
            if (response?.status === 200) {
                let result = await response.json();
                setAccShow1(false);
                setAccShow2(false);
                setAccShow3(true);
                setAccBg1(true);
                setAccBg2(true);
                setAccBg3(true);
                setSelectedShipment([]);
                shippingMethod(result?.consolidated_return_methods);
                setTimeout(() => {
                    scrollToParticularSection('section_3');
                }, 1000)
            }
            else {
                setDisablePaymentBtn(false);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
        }
    }

    const updateReturnMethod = async (event) => {
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + (JSON.parse(sessionStorage.getItem('RPI'))?.rpi_number) + '/selected-return-methods', {
                method: 'POST',
                body: JSON.stringify(selectedShipment),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
                }
            })
            if (!response) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
            if (response?.status === 200) {
                returnRequest();
            }
            else {
                setDisableReturnBtn(false);
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
        }
    }

    const confirmsubmit = (event) => {
        setDisableReturnBtn(true);
        updateReturnMethod();
        // returnRequest();
    }

    const returnReasonChange = (event, sku, shipId, index) => {
        let data = [...selectedReasonData]
        let i = data?.findIndex((obj) => obj.sku === sku);
        if (i !== -1) {
            // let yy = {
            //     title:event.target.value?.title,
            //     code:event.target.value?.code
            // }
            data[i].return_attribute.return_reason = event.target.value;
            // data[i].return_attribute.item_condition = {title:'',code:''};
            data[i].return_attribute.return_sub_reason = { title: '', code: '' };
            data[i].return_attribute.customer_notes = '';
        }
        setSelectedReasonData(data);

        //subreasons get
        let d = reasonsResult[index].return_attributes.reasons.findIndex((obj) => obj.title === event.target.value?.title);
        let t = { sku: sku, shipment_id: shipId, subReasons: reasonsResult[index].return_attributes.reasons[d] };
        let sub = [...getSubReasonData];
        let j = sub?.findIndex((obj) => obj.sku === sku);
        if (j !== -1) {
            sub[j] = t;
        }
        else {
            sub.push(t);
        }
        isSavedReasonDataChange(data);
        setGetSubReasonData(sub);
    };

    const returnSubReasonChange = (event, sku, shipId) => {
        let data = [...selectedReasonData]
        let i = data?.findIndex((obj) => obj.sku === sku && obj.shipment_id === shipId);
        if (i !== -1) {
            // let yy = {
            //     title:event.target.value?.title,
            //     code:event.target.value?.code
            // }
            data[i].return_attribute.return_sub_reason = event.target.value;
        }
        isSavedReasonDataChange(data);
        setSelectedReasonData(data);
    }

    const returnItemCondition = (event, sku, shipId) => {
        let data = [...selectedReasonData]
        let i = data?.findIndex((obj) => obj.sku === sku && obj.shipment_id === shipId);
        if (i !== -1) {
            // let yy = {
            //     title:event.target.value?.title,
            //     code:event.target.value?.code
            // }
            data[i].return_attribute.item_condition = event.target.value;
        }
        isSavedReasonDataChange(data);
        setSelectedReasonData(data);
    }

    const commentChange = (event, sku, shipId) => {
        let data = [...selectedReasonData]
        let i = data?.findIndex((obj) => obj.sku === sku && obj.shipment_id === shipId);
        if (i !== -1) {
            data[i].return_attribute.customer_notes = event.target.value;
        }
        setSelectedReasonData(data);
        isSavedReasonDataChange(data);
        let data1 = [...getSubReasonData]
        let j = data1?.findIndex((obj) => obj.sku === sku && obj.shipment_id === shipId)
        if (data[i]?.return_attribute.customer_notes.length >= data1[j].subReasons?.preferences.comment_max_char_size) {
            return false;
        }
        else {
            return true;
        }
    };

    const isSavedReasonDataChange = (obj) => {
        if (JSON.stringify(selectedSavedReasonData) !== JSON.stringify(obj)) {
            setDisableReasonBtn(false);
            setAccBg2(false);
            setAccBg3(false);
            setAccShow2(false);
            setAccShow3(false);
        }
        else {
            setDisableReasonBtn(true);
            if (paymentData.length > 0) {
                if (disablePaymentBtn) {
                    setDisablePaymentBtn(true);
                    setAccShow2(false);
                } else {
                    setDisablePaymentBtn(false);
                }
                setAccBg2(true);
            }
            if (shipmentData.length > 0) {
                setDisableReturnBtn(false);
                setAccBg3(true);
                setAccShow3(true);
            }
        }
    }

    const paymentMethod = (result) => {
        if (result.length > 0) {
            setPaymentData(result);
            let array = [];
            for (let i = 0; i < result.length; i++) {
                let t = {
                    "skus": result[i]?.skus,
                    "refund_method": result[i].refund_methods[0]
                }
                array.push(t);
            }
            setSelectedPayment(array);
        }
        else {
            setPaymentData(null);
        }
        //     try{
        //     const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + JSON.parse(sessionStorage.getItem('RPI')) + '/refund-methods', {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
        //             'order_id': orderData?.order_id
        //         }
        //     })
        //     if (!response) {
        //         setSnackOpen(true);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //         setPaymentData(null);
        //     }
        //     if (response?.status === 200) {
        //         let result = await response.json();
        //         if(result?.length > 0){
        //         setPaymentData(result);
        //         let array = [];
        //         for (let i = 0; i < result.length; i++) {
        //             let t = {
        //                 "skus": result[i]?.skus,
        //                 "refund_method": result[i].refund_methods[0]
        //             }
        //             array.push(t);
        //         }
        //         setSelectedPayment(array);             
        //      }
        //      else{
        //         setPaymentData(null);
        //      }       
        //     }
        //     else{
        //         setPaymentData(null);
        //         setSnackOpen(true);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //     }
        //    }
        //     catch (error) {
        //         setPaymentData(null);
        //         setSnackOpen(true);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //       }
    }

    const HtmlTooltipForAddress = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));
    const HtmlTooltipForDetails = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            // width: '400px',
            fontSize: theme.typography.pxToRem(12),
            border: '2px solid #dadde9',
        },
    }));

    const changePayment = (event, index, methods) => {
        let data = [...selectedPayment];
        let i = methods?.findIndex((obj) => obj?.name === event.target.value);
        if (i !== -1) {
            data[index].refund_method = methods[i];
        }
        setSelectedPayment(data);
    }

    const shippingMethod = async (result) => {
        if (result.length > 0) {
            setShipmentData(result);
            let array = [];
            for (let i = 0; i < result.length; i++) {
                let t = {
                    "skus": result[i]?.skus,
                    "rule_id": result[i]?.return_location_method_bundle_summary.rule_id,
                    "destination_address": result[i]?.return_location_method_bundle_summary.destination_address,
                    "location_id": result[i]?.return_location_method_bundle_summary.location_id,
                    "return_method": result[i]?.return_location_method_bundle_summary.return_method_summaries[0]
                }
                array.push(t);
            }
            setSelectedShipment(array);
        }
        else {
            setShipmentData(null);
        }
        //     try{
        //     const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + JSON.parse(sessionStorage.getItem('RPI')) + '/return-methods', {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId),
        //         }
        //     })
        //     if (!response) {
        //         setSnackOpen(true);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //         setShipmentData(null)
        //     }
        //     if (response?.status === 200) {
        //         let result = await response.json();
        //         if(result.length > 0){
        //         setShipmentData(result);
        //         let array = [];
        //         for (let i = 0; i < result.length; i++) {
        //             let t = {
        //                 "skus": result[i]?.skus,
        //                 "rule_id": result[i]?.return_location_method_bundle_summary.rule_id,
        //                 "location_id": result[i]?.return_location_method_bundle_summary.location_id,
        //                 "return_method": result[i]?.return_location_method_bundle_summary.return_method_summaries[0]
        //             }
        //             array.push(t);
        //         }
        //         setSelectedShipment(array);
        //      }
        //      else{
        //         setShipmentData(null);
        //      }
        //     }
        //     else{
        //         setShipmentData(null);
        //         setSnackOpen(true);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //     }
        //    }
        //     catch (error) {
        //         setSnackOpen(true);
        //         setShipmentData(null);
        //         setSnackbarProp({style:'error',msg:'Fetching data is unsuccessfull.'});
        //       }
    }


    const changeShipment = (event, index, methods) => {
        let data = [...selectedShipment]
        let i = methods?.findIndex((obj) => obj?.name === event.target.value);
        if (i !== -1) {
            data[index].return_method = methods[i];
        }
        setSelectedShipment(data);
    }

    const returnRequest = async () => {
        setLoaderMessage('Confirming return request...');
        setConfirmLoaderEnable(true);
        try {
            const response = await fetch(RETURNS_UI_URLS.BASE_URL + 'return/' + (JSON.parse(sessionStorage.getItem('RPI'))?.rpi_number) + '/confirm-return', {
                method: 'POST',
                body: {},
                headers: {
                    'Content-Type': 'application/json',
                    'X-Fenix-Tenant-Id': (JSON.parse(sessionStorage.getItem('retailerData')).tenantId)
                }
            });
            if (!response) {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            }
            if (response?.status === 200) {
                let result = await response.json();
                setSnackOpen(true);
                setSnackbarProp({ style: 'success', msg: 'Return request is successful.' });
                setRequestedResponse(result)
                setAccShow1(false);
                setAccBg1(true);
                setAccShow2(false);
                setAccBg2(true);
                setAccShow3(false);
                setAccBg3(true);
                setSelectedDummyReasonData(selectedReasonData);
                sessionStorage.removeItem('returnItemData');
                sessionStorage.removeItem('selectedReasonData');
                sessionStorage.removeItem('selectedDummyReasonData');
                sessionStorage.removeItem('isSelectedLineItems');
                // sessionStorage.removeItem('RPI');
                setConfirmLoaderEnable(false);
                setTimeout(() => {
                    setIsSubmitted(true);
                }, 1000);

                // setTimeout(() => {
                //     navigateToReturned('2');
                // }, 3000)
            }
            else {
                setSnackOpen(true);
                setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
                setConfirmLoaderEnable(false);
            }
        }
        catch (error) {
            setSnackOpen(true);
            setSnackbarProp({ style: 'error', msg: 'Fetching data is unsuccessfull.' });
            setConfirmLoaderEnable(false);
        }
    }
    const formatingDateForDisplay = (date) => {
        return date ? dateFormat(new Date(date), "mmmm d, yyyy") : '';
    }

    const addDefaultImg = ev => {
        ev.target.src = "/no-image.svg";
        ev.target.style = 'width:45%';

    }
    const returnSummaryBind = (data) => {
        // var maxDate = new Date(Math.max.apply(null, data.map(function(e) {
        //     return new Date(e.event_dttm);
        //   })));
        return data[0]?.description?.charAt(0).toUpperCase() + data[0]?.description?.slice(1).toLowerCase() + ' on ' + formatingDateForDisplay(data[0]?.event_dttm)
    }

    const displaySizeandColor = (size, color) => {
        return size && color ? (<span>Size: {size} &nbsp;&nbsp;|&nbsp;&nbsp; Color: {color}</span>) : (size && !color ? ('Size: ' + size) : (!size && color ? ('Color: ' + color) : ''))
    }

    const isValidReasons = (event) => {
        let flag = true;
        sessionStorage.setItem('selectedReasonData', JSON.stringify(selectedReasonData));
        for (let i = 0; i < selectedReasonData.length; i++) {
            let sub = [...getSubReasonData];
            let j = sub?.findIndex((obj) => obj.sku === selectedReasonData[i].sku);
            if (j !== -1) {
                if (selectedReasonData[i]?.return_attribute.return_reason.title && ((sub[j]?.subReasons?.preferences?.display_sub_reason) ? (selectedReasonData[i]?.return_attribute?.return_sub_reason?.title) : true) && ((sub[j]?.subReasons?.preferences?.check_item_conditions) ? (selectedReasonData[i]?.return_attribute?.item_condition?.title) : true) &&
                    ((sub[j]?.subReasons?.preferences?.capture_comments) ? (selectedReasonData[i]?.return_attribute.customer_notes) : true)) {
                    flag = false
                } else {
                    flag = true;
                    break;
                }
            }
            else {
                flag = true;
                break;
            }
        }
        return flag;
    }
    const handleTouchStart = () => {
        setShowTooltip(true);
    }
    const handleTouchEnd = () => {
        setShowTooltip(false)
    }
    const clikedAcc1 = () => {
        setCollapseOne(!collapseOne);
        setCollapseTwo(true);
        setCollapseThree(true)
        setAccBg1(true);
        setAccShow1(true);
        setAccShow2(false);
        setAccShow3(false);
    }
    const clikedAcc2 = () => {
        setCollapseTwo(!collapseTwo);
        setCollapseOne(true);
        setCollapseThree(true);
        setAccBg2(true);
        setAccShow1(false);
        setAccShow2(true);
        setAccShow3(false);
    }
    const clikedAcc3 = () => {
        setCollapseThree(!collapseThree);
        setCollapseTwo(true);
        setCollapseOne(true);
        setAccBg3(true);
        setAccShow1(false);
        setAccShow2(false);
        setAccShow3(true);
    }
    const NoIcon = styled('span')(({ theme }) => ({
        display: 'none',
    }));

    return (
        <div className="container px-28">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={navigateToMyOrders} className="f-14 cursor-pointer"><HomeIcon sx={{ fontSize: 20 }} /></Link>
                <Typography color="text.primary" className="f-14 fw-500 text-color cursor-pointer">Return Item</Typography>
            </Breadcrumbs>
            <div className="row">
                <div className="p-16 col-12">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item mb-20">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className={`full-width text-left p-10 f-16 fw-500 acc-btn ${accBg1 ? 'show' : 'collapsed'} ${accBg1 ? data1.type : null}`} type="button" onClick={clikedAcc1}
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <div className="row ml-0">
                                        <span className={`col-10 col-md-11 ${data1.type} textcolor item-center`}>Why are you returning this item(s)?</span>
                                        {/* <span className="fw-500 col-4 white-color" >
                                            {selectedReason}
                                            </span> */}
                                        <span className="text-right icon-size col-1" >
                                            {collapseOne && <ExpandMoreIcon className={`f-30  ${data1.type} textcolor  ${accBg1 ? 'white-color' : 'text-color'}`} />}
                                            {!collapseOne && <ExpandLessIcon className={`f-30 ${data1.type} textcolor ${accBg1 ? 'white-color' : 'text-color'}`} />}
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className={`accordion-collapse collapse ${accShow1 ? 'show' : ''}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="container underline" id="section_1">
                                        <div className="row py-10 f-14 hide-m">
                                            <div className="col-xs col-sm col-md mdisplay-flex">
                                                <div className='color-text fw-500'>Order Placed</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{formatingDateForDisplay(orderData?.date_place?.formatted_dttm)}</div>
                                            </div>
                                            {data1?.type !== 'janieandjack' ? <div className="col-xs col-sm col-md mdisplay-flex">
                                                <div className='color-text fw-500'>Total</div>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>$ {orderData?.price ? orderData?.price.toFixed(2) : '0'}</div>
                                            </div> : ''}
                                            <div className="col-sm col-xs col-md mdisplay-flex">
                                                <div className='width-fit-content'>

                                                    <HtmlTooltipForAddress enterTouchDelay={0}
                                                        title={
                                                            <React.Fragment>
                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} >
                                                                    <div className="fw-600 f-14">{orderData?.address?.name}</div>
                                                                    <div className="fw-400 f-12">{orderData?.address?.address1}</div>
                                                                    <div className="fw-400 f-12">{orderData?.address?.city},&nbsp;{orderData?.address?.province}</div>
                                                                    <div className="fw-400 f-12">{orderData?.address?.country}, &nbsp;{orderData?.address?.zip_code}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        }>
                                                        <span className='mdisplay-flex'>
                                                            <div className='color-text fw-500'>Ship to</div>
                                                            <div className={`fw-600 ${retailerInfo?.type} cursor-pointer mm-l16`}>{orderData?.address.name} <span className="text-color">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                                </svg></span>
                                                            </div></span>
                                                    </HtmlTooltipForAddress>
                                                </div>
                                            </div>
                                            <div className="col-xs col-sm col-md mdisplay-flex">
                                                <label className='color-text fw-500'>Order Number</label>
                                                <div className={`fw-600 ${data1?.type} mm-l16`}>{orderData?.order_number}</div>
                                            </div>
                                        </div>
                                        <div className='d-md-none py-10 f-14'>
                                            <div className='color-text fw-500'>Order Number:&nbsp;
                                                <HtmlTooltipForAddress enterTouchDelay={0}
                                                    title={
                                                        <React.Fragment>
                                                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                {/* <div className="row m-0 p-10 f-14 bg-color br-lf-rt-5">
                                                                <div className="col-md-3 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                    <div className='color-text fw-500'>Order Placed: &nbsp;&nbsp;</div>
                                                                    <div className={`fw-600 ${data1?.type} mm-l16`}>{formatingDateForDisplay(orderData?.date_place?.formatted_dttm)}</div>
                                                                </div>
                                                                <div className="col-md-2 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                    <div className='color-text fw-500'>Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                                    <div className={`fw-600 ${data1?.type} mm-l16`}>$ {orderData?.price ? orderData?.price : '0'}</div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-12 col-12 mdisplay-flex mpx-5 mb-10">
                                                                    <div className='color-text fw-500'>Ship to: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                                                    <div className={`fw-600 ${data1?.type} cursor-pointer mm-l16`}>{orderData?.address?.name} </div>
                                                                </div>
                                                            </div> */}
                                                                <div>Order Placed : &nbsp;{formatingDateForDisplay(orderData?.date_place?.formatted_dttm)}</div>
                                                                {data1?.type !== 'janieandjack' ? <div>Total : &nbsp;$ {orderData?.price ? orderData?.price.toFixed(2) : '0'}</div> : ''}
                                                                <div>Ship to : &nbsp;{orderData?.address?.name}</div>
                                                            </div>
                                                        </React.Fragment>}>
                                                    <span
                                                        className={`fw-600 ${data1?.type} text-underline`}>{orderData?.order_number}</span>
                                                </HtmlTooltipForAddress>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        {orderData?.line_data?.map((data, i) => {
                                            return (<div key={i} className={`row ${orderData?.line_data.length > i + 1 ? 'underline' : ''} py-10 mt-10`}>
                                                <div className="col-xs-7 col-sm-12 col-md-12 col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 text-center ">
                                                            {data1.type !== 'fluentcommerce' ?
                                                                <span>
                                                                    <img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='product'
                                                                        onClick={() => window.open(data?.routing_url ? data?.routing_url : data1.store_url)}
                                                                        src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                        onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                </span> :
                                                                <span>
                                                                    <img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='product'
                                                                        onClick={() => window.open(data1.store_url)}
                                                                        src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                        onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                </span>}
                                                        </div>
                                                        <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                            {data?.product_name ? <div className={`fw-500 f-14 ${retailerInfo?.type} cursor-pointer ${data.product_name?.length > 60 ? '' : 'width-fit-content'}`}>
                                                                <HtmlTooltipForDetails enterTouchDelay={0}
                                                                    title={
                                                                        <React.Fragment>
                                                                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                {data.product_name !== null && data.product_name ?
                                                                                    <div>Product Name : &nbsp;{data.product_name}</div> : ""}
                                                                                {/* {data.product_description !== null  && data.product_description ?
                                                                                    <div>Description : &nbsp;{data.product_description}</div>:""} */}
                                                                                {data.quantity !== null && data.quantity >= 0 ?
                                                                                    <div>Ordered Qty : &nbsp;{data.quantity}</div> : ''}
                                                                                {data.returnable_quantity !== null && data.returnable_quantity >= 0 ?
                                                                                    <div>Selected Qty : &nbsp;{data.returnable_quantity}</div> : ''}
                                                                            </div>
                                                                        </React.Fragment>}>
                                                                    {showtooltip || (
                                                                        <span className='mdisplay-flex'>
                                                                            <div className='display-ellipsis'>{data.product_name}</div>
                                                                        </span>
                                                                    )}
                                                                </HtmlTooltipForDetails>
                                                            </div> :
                                                            <div className={`fw-500 f-14 ${data1?.type}`}>{data?.sku}</div>}
                                                            {(data?.color || data?.size) ? <div className='row mb-5px f-12 '>
                                                                <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(data?.size, data?.color)}</div>
                                                            </div> : ''}
                                                            {data?.return_summary === null ?
                                                                <div className={`pr-10 f-12 fw-500 mb-5px ${data?.return_context?.eligible_to_returns ? data1.type : "warning-color"}`}>
                                                                    {(data?.return_context?.in_eligible_reason) ?
                                                                        (data?.return_context?.in_eligible_reason) : 'Return eligible through'}
                                                                    &nbsp;{data?.return_context?.max_return_date ? formatingDateForDisplay(data?.return_context?.max_return_date?.formatted_dttm) : ''}</div> : <div className={`pr-10 f-12 mb-5px fw-500 ${data?.return_summary !== null ? 'janieandjack-red-color' : "warning-color"}`}>{returnSummaryBind(data.return_summary)}</div>}
                                                            <div className='row'>
                                                                <label className='f-12 tmb10'>Selected Qty:&nbsp;&nbsp;{data.returnable_quantity}</label>
                                                                {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(data.routing_url)}>Buy it again </button> */}
                                                                {/* <button className=" secondary-btn w-125 ml-10" onClick={() => window.open(data.routing_url)}>View your item </button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-5 col-sm-12 col-md-12 col-lg-5">
                                                    {!isLoaderEnable ? reasonsResult?.map((obj, index) =>
                                                        obj.sku === data.sku && obj.shipment_id === data.shipment_id ? (<div key={index}>
                                                            <div key={index} className="text-color fw-600 f-14 mb-10 required">Why are you returning this item? </div>
                                                            <div className="drop-down mb-10" >
                                                                <FormControl sx={{ m: 0 }} fullWidth size="small">
                                                                    <Select disabled={isSubmitted} IconComponent={NoIcon} className={`${data1.type} form-select p-10 border-line select-h-35 box-shadow-none cursor-pointer f-14`} defaultValue={''} displayEmpty
                                                                        onChange={(e) => returnReasonChange(e, obj.sku, obj.shipment_id, index)} >
                                                                        <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} value="">Select Any Reason</MenuItem>
                                                                        {obj?.return_attributes?.reasons.map((e, key) => {
                                                                            return <MenuItem className={`${data1.type + '-menuitem'}`} key={key} value={e}
                                                                                sx={{ whiteSpace: 'normal', wordWrap: 'break-word', mr: 2 }}>{e.title}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>

                                                            {getSubReasonData.map((sub, j) => sub.sku === data.sku && sub.shipment_id === data.shipment_id && (sub?.subReasons?.sub_reasons?.length > 0) && (sub?.subReasons?.preferences?.display_sub_reason) ?
                                                                (<div className="drop-down mb-10" key={j}>
                                                                    <div className="text-color fw-600 f-14 mb-10 required">Please tell us more</div>
                                                                    <div>
                                                                        {/* value={selectedReasonData[index]?.return_attribute.return_sub_reason.title ? selectedReasonData[index]?.return_attribute.return_sub_reason : ''} */}
                                                                        <FormControl sx={{ m: 0 }} fullWidth size='small'>
                                                                            <Select disabled={isSubmitted} IconComponent={NoIcon} value={selectedReasonData[index]?.return_attribute.return_sub_reason.title ? selectedReasonData[index]?.return_attribute.return_sub_reason : ''}
                                                                                className={`${data1.type} form-select p-10 select-h-35 border-line box-shadow-none cursor-pointer f-14`} defaultValue={''} displayEmpty
                                                                                onChange={(e) => returnSubReasonChange(e, obj.sku, obj.shipment_id)} aria-label="Default select example">
                                                                                <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} value="">Select Any Sub Reason</MenuItem>
                                                                                {sub?.subReasons?.sub_reasons.map((e, key) => {
                                                                                    return <MenuItem className={`${data1.type + '-menuitem'}`} key={key} value={e}>{e.title}</MenuItem>
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>) : '')}
                                                            {obj?.return_attributes?.item_conditions?.length > 0 && selectedReasonData[index]?.return_attribute?.return_reason && getSubReasonData.map((sub) => sub.sku === data.sku && sub.shipment_id === data.shipment_id && sub?.subReasons?.preferences?.check_item_conditions ?
                                                                <div className="className = drop-down">
                                                                    <div className="text-color fw-600 f-14 mb-10 required">Item Condition</div>
                                                                    <div>
                                                                        {/* value={selectedReasonData[index]?.return_attribute?.item_condition.title ? selectedReasonData[index]?.return_attribute?.item_condition : ''} */}
                                                                        <FormControl sx={{ m: 0 }} fullWidth size='small'>
                                                                            <Select disabled={isSubmitted} IconComponent={NoIcon} value={selectedReasonData[index]?.return_attribute?.item_condition.title ? selectedReasonData[index]?.return_attribute?.item_condition : ''}
                                                                                className={`${data1.type} form-select select-h-35 p-10 border-line box-shadow-none cursor-pointer f-14`} displayEmpty
                                                                                defaultValue={''} onChange={(e) => returnItemCondition(e, obj.sku, obj.shipment_id)} aria-label="Default select example">
                                                                                <MenuItem className={`f-14 ${data1.type + '-menuitem'}`} value="">Select Item Condition</MenuItem>
                                                                                {obj?.return_attributes?.item_conditions.map((data, key) => {
                                                                                    return <MenuItem className={`${data1.type + '-menuitem'}`} key={key} value={data}>{data.title}</MenuItem>
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div> : '')
                                                            }
                                                            {getSubReasonData.map((sub, l) => sub.sku === data.sku ?
                                                                <div className="additional-info mb-30 mt-20" key={l}>
                                                                    <div className={`f-14 fw-600 text-color mb-10 ${sub.sku === data.sku && sub?.subReasons?.preferences?.capture_comments ? 'required' : ''}`}>Additional Information</div>
                                                                    <div className="text-area f-14">
                                                                        <textarea disabled={isSubmitted} name="post content" value={selectedReasonData[index]?.return_attribute?.customer_notes ? selectedReasonData[index]?.return_attribute?.customer_notes : ''} className={`br-10 p-12 full-width border-line ${data1.type} ${isSubmitted ? 'op-5 cursor-not-allowed' : ''}`} maxLength={sub?.subReasons?.preferences.comment_max_char_size} rows={2} onChange={(e) => commentChange(e, obj.sku, obj.shipment_id)} />
                                                                        {!isSubmitted ? <p><b>{(sub?.subReasons?.preferences.comment_max_char_size ? sub?.subReasons?.preferences.comment_max_char_size : 0) - (selectedReasonData[index]?.return_attribute.customer_notes.length ? selectedReasonData[index]?.return_attribute.customer_notes.length : 0)}</b> Characters Left </p> : ''}
                                                                    </div>
                                                                </div> : '')}

                                                            {/* {selectedReasonData?.upload_product_images?.required ?
                                                  <div className="upload-attachments">
                                                  <label className="f-14 text-color fw-600 mb-10">Upload attachments</label> 
                                                     <div className="upload-file">
                                                         <img src={upload} alt="upload file"/><span className="fw-600 f-14 text-color"> Drag & drop files or </span>
                                                         <label for="file-upload" className="custom-file-upload f-14">Browse</label>
                                                         <input id="file-upload" type="file"/>
                                                     </div>
                                                 </div>:''} */}
                                                        </div>) : '') : <div className="loader mt-2rem item-center">Loading...</div>
                                                    }
                                                </div>
                                            </div>
                                            )
                                        })}
                                        <div className="row">
                                            <div className="col-md-10"></div>
                                            <div className="col-md-2">
                                                <button disabled={isValidReasons() || disableReasonBtn} className={`float-right fw-500 f-14 ${!isValidReasons() && !disableReasonBtn ? (data1?.type ? data1.type + '-btn' : '') : 'disabled-btn'} `}
                                                    onClick={openAcc1}>Next &nbsp;<ArrowForwardIcon className="f-20 " /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!(JSON.parse(sessionStorage.getItem('RPI')))?.disable_refund_methods_selection ? <div className="accordion-item mb-20">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button disabled={!accBg2} className={`full-width text-left p-10 f-16 fw-500 acc-btn  ${accBg2 ? 'show' : 'collapsed'} ${accBg2 ? data1.type : null}`} type="button" onClick={clikedAcc2}
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <div className="row ml-0">
                                        <span className={`col-10 col-md-11 item-center ${data1.type} textcolor  ${accBg2 ? 'white-color' : 'text-color'}`}>How can we make it right?</span>
                                        {/* <span className={`fw-500 col-6 ${accBg2 ? 'white-color' : 'text-color'}`} >
                                            {selectedPayment}
                                        </span> */}
                                        <span className="text-right icon-size col-1" >
                                            {collapseTwo && <ExpandMoreIcon className={`f-30 ${data1.type} textcolor ${accBg2 ? 'white-color' : 'text-color'}`} />}
                                            {!collapseTwo && <ExpandLessIcon className={`f-30 ${data1.type} textcolor ${accBg2 ? 'white-color' : 'text-color'}`} />}
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className={`accordion-collapse collapse ${accShow2 ? 'show' : ''}`} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="container" id="section_2">
                                        {paymentData && paymentData.length > 0 ? paymentData?.map((payData, index) => {
                                            return (<div key={index} className={`row py-10 ${paymentData.length > index + 1 ? 'underline' : ''}`}>
                                                <div className={`col-xs-6 col-sm-6 col-md-6`}>{
                                                    orderData?.line_data?.map((data, i) => {
                                                        return (payData.skus?.map((sk) => sk === data.sku ?
                                                            // ${orderData?.line_data.length > i + 1 ? 'underline' : ''}
                                                            <div className="row" key={i}>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-3 text-center ">
                                                                    {data1.type !== 'fluentcommerce' ?
                                                                        <span>
                                                                            <img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='Product'
                                                                                onClick={() => window.open(data?.routing_url ? data?.routing_url : data1.store_url)}
                                                                                src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                        </span> :
                                                                        <span>
                                                                            <img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='Product'
                                                                                onClick={() => window.open(data1.store_url)}
                                                                                src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                        </span>}
                                                                </div>
                                                                <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                                    {data?.product_name ? <div className={`fw-500 f-14 ${retailerInfo?.type} cursor-pointer ${data.product_name?.length > 50 ? '' : 'width-fit-content'}`}>
                                                                        <HtmlTooltipForDetails enterTouchDelay={0}
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                        {data.product_name !== null && data.product_name ?
                                                                                            <div>Product Name : &nbsp;{data.product_name}</div> : ""}
                                                                                        {/* {data.product_description !== null  && data.product_description ?
                                                                                    <div>Description : &nbsp;{data.product_description}</div>:""} */}
                                                                                        {data.quantity !== null && data.quantity >= 0 ?
                                                                                            <div>Ordered Qty : &nbsp;{data.quantity}</div> : ''}
                                                                                        {data.returnable_quantity !== null && data.returnable_quantity >= 0 ?
                                                                                            <div>Selected Qty : &nbsp;{data.returnable_quantity}</div> : ''}
                                                                                    </div>
                                                                                </React.Fragment>}>
                                                                            {showtooltip || (
                                                                                <span className='mdisplay-flex'>
                                                                                    <div className='display-ellipsis'>{data.product_name}</div>
                                                                                </span>
                                                                            )}
                                                                        </HtmlTooltipForDetails>
                                                                    </div>:
                                                                        <div className={`fw-500 f-14 ${data1?.type}`}>{data?.sku}</div>}
                                                                    {(data?.color || data?.size) ? <div className='row mb-5px f-12 '>
                                                                        <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(data?.size, data?.color)}</div>
                                                                    </div> : ''}
                                                                    {data?.return_summary === null ?
                                                                        (data?.return_context?.max_return_date ? <div className={`pr-10 f-12 fw-500 mb-5px ${data?.return_context?.eligible_to_returns ? data1.type : "warning-color"}`}>
                                                                            {(data?.return_context?.in_eligible_reason) ?
                                                                                (data?.return_context?.in_eligible_reason) : 'Return eligible through'}
                                                                            &nbsp;{data?.return_context?.max_return_date ? formatingDateForDisplay(data?.return_context?.max_return_date?.formatted_dttm) : ''}</div> : '') : <div className={`pr-10 f-12 mb-5px fw-500 ${data?.return_summary !== null ? 'janieandjack-red-color' : "warning-color"}`}>{returnSummaryBind(data.return_summary)}</div>}
                                                                    {data?.returnable_quantity ? <div className='row'>
                                                                        <label className='f-12 tmb10'>Selected Qty:&nbsp;&nbsp;{data.returnable_quantity}</label>
                                                                        {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(data.routing_url)}>Buy it again </button> */}
                                                                        {/* <button className=" secondary-btn w-125 ml-10" onClick={() => window.open(data.routing_url)}>View your item </button> */}
                                                                    </div> : ''}
                                                                </div>
                                                            </div> : '')
                                                        )
                                                    })}
                                                </div>
                                                <div className="col-xs col-sm col-md ml-16 mml5">
                                                    <div className="radio-btn">
                                                        <FormControl>
                                                            <RadioGroup disabled={isSubmitted} className={`${isSubmitted ? 'op-5 cursor-not-allowed no-pointer-events' : ''}`} value={selectedPayment[index]?.refund_method?.name ? selectedPayment[index]?.refund_method?.name : ""} onChange={(e) => changePayment(e, index, payData?.refund_methods)} aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="replace" name="radio-buttons-group" sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                }}>
                                                                {payData?.refund_methods?.map((refundData, l) => {
                                                                    return <div key={l}>
                                                                        <FormControlLabel className={`f-14 fw-600 ${data1.type}`} value={refundData?.name} control={<Radio disabled={isSubmitted} className={`${data1.type}`} />} label={refundData?.name} ></FormControlLabel>
                                                                        {selectedPayment[index]?.refund_method?.name === refundData?.name ? <div className="ml-26 f-12 fw-500" dangerouslySetInnerHTML={{ __html: refundData.description }} ></div> : ''}
                                                                        {/* {payData?.refund_methods?.length > l + 1 ? <div className="line" ></div> : ''} */}
                                                                    </div>
                                                                })}
                                                                {/* : <div className="fw-500 f-14 text-center">No Payment Method Found</div> */}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>

                                                </div>
                                            </div>)
                                        }) : paymentData === null ? <div className='f-14 fw-500 text-center mt-16 mb-20'>No Data Found</div> : ''}

                                        <div className="overflow-hidden">
                                            <button className={`float-right fw-500 f-14 mt-10 ${paymentData && paymentData.length > 0 && !disablePaymentBtn ? (data1?.type ? data1.type + '-btn' : '') : 'disabled-btn'}`} onClick={openAcc2}>Next &nbsp;<ArrowForwardIcon className="f-20" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                        <div className="accordion-item mb-20">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button disabled={!accBg3} className={`full-width text-left p-10 f-16 fw-500 acc-btn  ${accBg3 ? 'show' : 'collapsed'} ${accBg3 ? data1.type : null}`} type="button" onClick={clikedAcc3}
                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <div className="row ml-0">
                                        <span className={`col-10 col-md-11 ${data1.type} textcolor item-center ${accBg3 ? 'white-color' : 'text-color'}`}>How would you like to return your item(s)?</span>
                                        {/* <span className="fw-500 col-6 white-color" >
                                            {selectedShipment}
                                            </span> */}
                                        <span className={`text-right icon-size col-1`} >

                                            {collapseThree && <ExpandMoreIcon className={`f-30 ${data1.type} textcolor ${accBg3 ? 'white-color' : 'text-color'}`} />}
                                            {!collapseThree && <ExpandLessIcon className={`f-30 ${data1.type} textcolor ${accBg3 ? 'white-color' : 'text-color'}`} />}
                                        </span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className={`accordion-collapse collapse ${accShow3 ? 'show' : ''}`} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="container" id="section_3">
                                        {shipmentData && shipmentData.length > 0 ? shipmentData?.map((shipData, index) => {
                                            return (
                                                <div key={index} className={`row ${shipmentData?.length > index + 1 ? 'underline' : ''} py-10`}>
                                                    <div className={`col-xs-6 col-sm-6 col-md-6 col-lg-6`}>
                                                        {orderData?.line_data?.map((data, i) => {
                                                            return (shipData.skus?.map((sk) => sk === data.sku ?
                                                                // ${orderData?.line_data.length > i + 1 ? 'underline' : ''}
                                                                <div className="row" key={i}>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 text-center ">
                                                                        {data1.type !== 'fluentcommerce' ?
                                                                            <span> <img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='Product' onClick={() => window.open(data?.routing_url ? data?.routing_url : data1.store_url)}
                                                                                src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                onError={addDefaultImg} style={{ width: 100 + 'px' }}></img></span> :
                                                                            <span><img className={`img-block mb-10 cursor-pointer ${!data.image_url ? "width-45per" : "border-image"}`} alt='Product' onClick={() => window.open(data1.store_url)}
                                                                                src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span>}
                                                                    </div>
                                                                    {/* <div className="col-lg-3 col-md-2 col-sm-2 col-3 text-center ">
                                                                        {data1.type !== 'fluentcommerce' ?
                                                                            <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!data.image_url ? "width-45per" : ""}`} alt='product'
                                                                                    onClick={() => window.open(data?.routing_url ? data?.routing_url : data1.store_url)}
                                                                                    src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span> :
                                                                            <span>
                                                                                <img className={`img-block border-image mb-10 cursor-pointer ${!data.image_url ? "width-45per" : ""}`} alt='product'
                                                                                    onClick={() => window.open(data1.store_url)}
                                                                                    src={data?.image_url ? `${data.image_url}` : '/no-image.svg'}
                                                                                    onError={addDefaultImg} style={{ width: 100 + 'px' }}></img>
                                                                            </span>}
                                                                    </div> */}
                                                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                                                        {data?.product_name ? <div className={`fw-500 f-14 ${retailerInfo?.type} cursor-pointer ${data.product_name?.length > 50 ? '' : 'width-fit-content'}`}>
                                                                            <HtmlTooltipForDetails enterTouchDelay={0}
                                                                                title={
                                                                                    <React.Fragment>
                                                                                        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} className="fw-500 pd-5 tooltip-wd">
                                                                                            {data.product_name !== null && data.product_name ?
                                                                                                <div>Product Name : &nbsp;{data.product_name}</div> : ""}
                                                                                            {/* {data.product_description !== null  && data.product_description ?
                                                                                    <div>Description : &nbsp;{data.product_description}</div>:""} */}
                                                                                            {data.quantity !== null && data.quantity >= 0 ?
                                                                                                <div>Ordered Qty : &nbsp;{data.quantity}</div> : ''}
                                                                                            {data.returnable_quantity !== null && data.returnable_quantity >= 0 ?
                                                                                                <div>Selected Qty : &nbsp;{data.returnable_quantity}</div> : ''}
                                                                                        </div>
                                                                                    </React.Fragment>}>
                                                                                {showtooltip || (
                                                                                    <span className='mdisplay-flex'>
                                                                                        <div className='display-ellipsis'>{data.product_name}</div>
                                                                                    </span>
                                                                                )}
                                                                            </HtmlTooltipForDetails>
                                                                        </div>:
                                                                        <div className={`fw-500 f-14 ${data1?.type}`}>{data?.sku}</div>}
                                                                        {(data?.color || data?.size) ? <div className='row mb-5px f-12 '>
                                                                            <div className='col-12 dark-grey-color fw-500'> {displaySizeandColor(data?.size, data?.color)}</div>
                                                                        </div> : ''}
                                                                        {/* <div className='fw-500 f-14 dark-grey-color'>{linedata.product_description}</div> */}
                                                                        {data?.return_summary === null ?
                                                                            <div className={`pr-10 f-12 fw-500 mb-5px ${data?.return_context?.eligible_to_returns ? data1.type : "warning-color"}`}>
                                                                                {(data?.return_context?.in_eligible_reason) ?
                                                                                    (data?.return_context?.in_eligible_reason) : 'Return eligible through'}
                                                                                &nbsp;{data?.return_context?.max_return_date ? formatingDateForDisplay(data?.return_context?.max_return_date?.formatted_dttm) : ''}</div> : <div className={`pr-10 f-12 mb-5px fw-500 ${data?.return_summary !== null ? 'janieandjack-red-color' : "warning-color"}`}>{returnSummaryBind(data.return_summary)}</div>}
                                                                        <div className='row'>
                                                                            <label className='f-12 tmb10'>Selected Qty:&nbsp;&nbsp;{data.returnable_quantity}</label>
                                                                            {/* <button className={`${data1.type} tertiary-btn w-125`} onClick={() => window.open(data.routing_url)}>Buy it again </button> */}
                                                                            {/* <button className=" secondary-btn w-125 ml-10" onClick={() => window.open(data.routing_url)}>View your item </button> */}
                                                                        </div>
                                                                    </div>
                                                                </div> : '')
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-xs col-sm col-md ml-16 mml5">
                                                        <div className="radio-btn">
                                                            <FormControl>
                                                                {/* value={setShippingMethodRadio(data?.id,selectedShipment[i])}  */}
                                                                <RadioGroup disabled={isSubmitted} className={`${isSubmitted ? 'op-5 cursor-not-allowed no-pointer-events' : ''}`} value={selectedShipment[index]?.return_method?.name ? selectedShipment[index]?.return_method?.name : ""} onChange={(e) => changeShipment(e, index, shipData?.return_location_method_bundle_summary?.return_method_summaries)}
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="return label"
                                                                    name="radio-buttons-group" sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 18,
                                                                        },
                                                                    }}>
                                                                    {shipData?.return_location_method_bundle_summary?.return_method_summaries?.map((methodData, j) => {
                                                                        return <div key={j}>
                                                                            <FormControlLabel className={`f-14 fw-600 ${data1.type}`} value={methodData?.name} control={<Radio disabled={isSubmitted} className={`${data1.type}`} />} label={methodData?.name} ></FormControlLabel>
                                                                            <span className={`float-right f-14 fw-600 ${methodData?.label_cost?.amount === 0 ? "success-color" : "warning-color"}`}>{methodData.label_cost.currency === 'USD' && methodData.label_cost?.amount !== 0 ? '$' : ''} {methodData.label_cost?.amount === 0 ? 'FREE' : methodData.label_cost?.amount}</span>
                                                                            {/* {data?.id === selectedShipment[i]?.reasonId ? (selectedShipment[i]?.value === methodData?.name ? <div className="ml-26 f-12 fw-500" dangerouslySetInnerHTML={{ __html: methodData.description }} ></div> : ''):''} */}
                                                                            {selectedShipment[index]?.return_method?.name === methodData?.name ? <div className={`ml-26 f-12 fw-500 }`} dangerouslySetInnerHTML={{ __html: methodData.description }} ></div> : ''}

                                                                            {/* {shipData?.return_methods?.length > j + 1 ? <div className="line" ></div> : ''} */}
                                                                        </div>
                                                                    })}
                                                                    {/* : <div className="fw-500 f-14 text-center">No Shipping Method Found</div> */}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : shipmentData === null ? <div className='f-14 fw-500 text-center mt-16 mb-20'>No Data Found</div> : ''}

                                        <div className="row">
                                            <div className="col-xs col-sm col-md">
                                                <div className="return-confirm overflow-hidden">
                                                    <button className={`float-right fw-600 f-14 ${shipmentData && shipmentData.length > 0 ? data1.type + '-btn' : null}  ${shipmentData && shipmentData.length > 0 && !disableReturnBtn ? (data?.type ? data.type + '-btn' : '') : 'disabled-btn'}`} onClick={confirmsubmit}>Confirm Return &nbsp;<ArrowForwardIcon className="f-20" /></button>                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {confirmLoaderEnable ?
                            <div className="align-center loader">{loaderMessage}</div> : ''
                        }
                        <div> {isSubmitted ? <div id="section_4"><ReturnAuthorization data={requestedResponse} /></div> : ''}</div>


                    </div>
                </div>
            </div>
            <SimpleDialog
                open={open}
                onClose={dialogClose}
            />
            <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarProp.style}
                    variant="filled"
                    sx={{ width: '100%' }}
                >{snackbarProp.msg}
                </Alert></Snackbar>
        </div>
    );

}

export default ReturnItem;

import { useEffect,useState } from "react";
import Navbar from "../navbar/navbar";
import Loginportal from "../return portal/return-portal";
import Footer from "../footter/footer";
// import BestSellers from "../best-sellers/best-sellers";
import Fenixorder from "../return portal/fenix orders/fenix-order";
import { useNavigate } from "react-router-dom";

function TravelPro(){
    const [showDelayedFile, setShowDelayedFile] = useState(false);
    const navigate = useNavigate();    

    let obj ={
        type:'travelpro',
        fileUrl:'travelpro/travelpro-logo.png',
        vendorName:'Travelpro',
        menuName:[{'menu':'SHOP ALL','hrefLink':'https://travelpro.com/collections/all'},{'menu':'LUGGAGE','hrefLink':'https://travelpro.com/pages/shop-luggage-by-type'},{'menu':'BACKPACKS','hrefLink':'https://travelpro.com/collections/backpacks'},{'menu':'BAGS','hrefLink':'https://travelpro.com/collections/travelpro-bags'},{'menu':'ACCESSORIES','hrefLink':'https://travelpro.com/collections/travelpro-essentials'},{'menu':'COLLECTIONS','hrefLink':'https://travelpro.com/pages/view-by-collection'},{'menu':'ABOUT US','hrefLink':'https://travelpro.com/pages/about-travelpro'}],
        login_img:'travelpro/travelpro-login-image2.png',
        storeName:'mytravelpro.myshopify.com',
        tenantId:'613abc6af6ea4c488d9e38cbb159453d',
        recommendedProducts:[{'image_url':'travelpro/medium-luggage.png','title':'Maxlite® Air Medium','price':'$186.99'},{'image_url':'travelpro/medium-luggage-01.png','title':'Platinum® Elite Medium','price':'$ 343.99'},
            {'image_url':'travelpro/maxlite-dropbottom.png','title':'Maxlit Drop-Bottom','price':'$ 101.99'},{'image_url':'travelpro/medium-topload-03.png','title':'Maxlite® Laptop Backpack','price':'$ 101.32'}
        ],
        footer_header:[{'header_name':'SHOP','nested_values':[{'value':'Carry On Luggage','hrefLink':'https://travelpro.com/collections/carry-on-luggage'},{'value':'Checked Luggage','hrefLink':'https://travelpro.com/collections/checked-luggage'},{'value':'Hard Shell Luggage ','hrefLink':'https://travelpro.com/collections/hard-side-luggage'},{'value':'Garment Bags','hrefLink':'https://travelpro.com/collections/garment-bags'}]},
            {'header_name':'CUSTOMER SERVICE','nested_values':[{'value':'Customer Service Center','hrefLink':'https://travelpro.com/pages/customer-service-center'},{'value':'FAQs','hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions'},{'value':'Orders and Shipping','hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions#orders'},{'value':'Returns & Refunds', 'hrefLink':'https://travelpro.com/pages/travelpro-f-a-q-frequently-asked-questions#Returns'},{'value':'Contact Customer Service','hrefLink':'https://travelpro.com/pages/contact-travelpro-products'}]},
            {'header_name':'DISCOVER','nested_values':[{'value':'About Travel Pro','hrefLink':'https://travelpro.com/pages/about-travelpro'},{'value':'Our History','hrefLink':'https://travelpro.com/pages/our-history'},{'value':'TravelPRO Guides','hrefLink':'https://travelpro.com/pages/travelpro-guides-hub'},{'value':'Blog', 'hrefLink':'https://travelpro.com/blogs/the-travelpro-blog'}]},
            {'header_name':'STAY CONNECTED','nested_values':[{'value':'Facebook','hrefLink':'https://www.facebook.com/travelproproducts/'},{'value':'Instagram','hrefLink':'https://www.instagram.com/travelprointl/'},{'value':'Twitter','hrefLink':'https://x.com/travelprointl'},{'value':'Youtube','hrefLink':'https://www.youtube.com/user/TravelproLuggage'}]},
        ],
        store_url:'https://travelpro.com/',
        policyUrl:'https://www.janieandjack.com/returns-policy.html?lang=en_US'

    }
    sessionStorage.setItem('retailerData', JSON.stringify(obj));

    useEffect(() => {
        setTimeout(() => {
            setShowDelayedFile(true);
        }, 1000); 
        let tmp = document.location.href.slice(document.location.href?.lastIndexOf("/") , document.location.href?.length) ;        
        if(tmp === '/'  || document.location.href.includes('login') || (!document.location.search && !sessionStorage.getItem('email'))) {
            let data2 = ''; 
            sessionStorage.removeItem('returnItemData');
            sessionStorage.removeItem('selectedReasonData');
            sessionStorage.removeItem('selectedDummyReasonData');
            sessionStorage.removeItem('isSelectedLineItems');
            sessionStorage.removeItem('RPI');
            sessionStorage.removeItem('email'); 
            sessionStorage.removeItem('tabIndex');
            sessionStorage.removeItem('orderInfo');
            sessionStorage.removeItem('retailername');
            data2 = '/login';
            navigate(data2.replace(/"/g, "")); 
        }      
       },[navigate,obj.type]);
    
    return (
        <div className="hidden-overflow">
            <Navbar data={obj}></Navbar>
            {(document.location.search || sessionStorage.getItem('email')) && !(document.location.href.includes('login')) ? <Fenixorder data={JSON.parse(JSON.stringify(obj))}></Fenixorder> : <Loginportal data={JSON.parse(JSON.stringify(obj))}></Loginportal>}
            {/* <BestSellers data={obj}></BestSellers> */}
            {showDelayedFile && (
            <Footer data={obj}></Footer>
            )}
        </div>
      );
}



export default TravelPro;
